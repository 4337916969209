@import "~@element/ui/modules/core";

body {
  margin: 0;
}

#research-go-app {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .mayo-logo-placeholder {
    max-height: 15em;
    margin-top: auto;
    margin-bottom: auto;
    opacity: 0.6;
  }
  .msg-trouble {
    margin-top: 0.5em;
    font-size: 0.6em;
    padding: 0.5em;
    background-color: $color-red-20;
    @include shadow(1);
  }
}
